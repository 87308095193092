@use 'sass:math';
@import '@styles/variables';
@import '@styles/keyframes';
@import '@styles/mixins';

.gridWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 48px;

    @media (max-width: ($mobile)) {
        row-gap: 24px;
    }
}

.grid {
    &_toysVertical {
        @media (max-width: ($mobile)) {
            margin: 0 -15px;

            .grid__page {
                @include GridVertical;
            }
        }
    }

    &_feed {
        .grid__page {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: ($mobile)) {
                gap: 0;
            }
        }

        .grid__banner {
            display: none;

            @media (max-width: ($tablet)) {
                display: block;
                margin: 24px 0;
            }
        }
    }

    //.grid_qrCodes
    &_qrCodes {
        .grid__page {
            display: grid;
            grid-auto-rows: 1fr;
            justify-content: center;
            row-gap: 8px;
            grid-template-columns: repeat(auto-fill, 350px);
            gap: 32px;
        }
    }

    //.grid_artists
    &_artists {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;

        &>*:not(:last-child) {
            padding-bottom: 16px;

            @media (min-width: ($mobile)) {
                padding-bottom: 48px;
            }
        }

        .grid__page {
            grid-template-columns: repeat(2, 140px);
            gap: 16px 32px;

            @media (min-width: ($mobile)) {
                grid-template-columns: repeat(4, 140px);
                gap: 48px 32px;
            }

            @media (min-width: ($containerMediaAr)) {
                grid-template-columns: repeat(auto-fit, 140px);
            }
        }
    }

    //.grid__page
    &__page {
        @include Grid;
        //opacity: 0;
        //animation: showBlock 300ms 1 linear 200ms forwards;

        //.grid__page_banner
        &_banner {
            //transition: opacity 0.3s ease 0s;
        }
    }

    //.grid__banner
    &__banner {
        min-width: 270px;
        align-self: stretch;

        @media (max-width: ($tablet)) and (min-width: ($mobile)) {

            //.grid__banner_horizontal
            &_horizontal {
                grid-column: 1/3 !important;
            }
        }

        @media (max-width: ($mobile)) {
            grid-row: auto !important;
            grid-column: auto !important;
        }
    }
}