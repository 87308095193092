@use 'sass:math';
@import '@styles/variables';

.inputBox {
    //.inputBox__label
    &__label {
        font-weight: 500;
        line-height: 140%;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    //.inputBox__row
    &__row {
        width: 100%;

        padding: 13px 16px;
        min-height: 48px;
        border-radius: 8px;
        box-shadow: inset 0 0 0 1.5px var(--Silver-Lake);
        background: var(--Bleached-Silk);


        --column-gap: 8px;
        --close-icon-size: 20px;

        display: flex;
        column-gap: var(--column-gap);

        transition: background 0.3s ease 0s, box-shadow 0.3s ease 0s;

        & > svg {
            align-self: center;
            flex: 0 0 20px;
        }

        @media (any-hover: hover) {
            &:hover {
                box-shadow: inset 0 0 0 1.5px var(--Wild-Dove);
            }
        }

        //.inputBox__row_withLeftIcon
        &_withLeftIcon {
            --icon-size: 20px;

            .inputBox__leftIcon {
                width: var(--icon-size);
                height: var(--icon-size);
            }

            .inputBox__input {
                flex: 1 0 calc(100% - var(--close-icon-size) - var(--icon-size) - (var(--column-gap) * 2));
            }
        }

        &:focus-within {
            outline: none;
            box-shadow: inset 0 0 0 1.5px var(--Goshawk-Grey), 0 0 0 2px var(--Wild-Dove);
            background: var(--white);

        }

        &:not(:focus-within) {
            .inputBox__close {
                opacity: 0;
                pointer-events: none;
            }
        }

        &:hover {
            .inputBox__close {
                opacity: 1;
                pointer-events: auto;
            }
        }


        //.inputBox__row_filled
        &_filled {
            box-shadow: inset 0 0 0 1.5px var(--Silver-Lake);
            background: var(--white);
        }

        //.inputBox__row_disabled
        &_disabled {
            background: var(--Zhn-Zh-Bi-Pearl);
            color: var(--Silver-Lake);
        }

        //.inputBox__row_error
        &_error {
            box-shadow: inset 0 0 0 1.5px var(--Mandarin-Red);
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    //.inputBox__input
    &__input {
        flex: 1 0 calc(100% - var(--close-icon-size) - var(--column-gap));
        min-width: 0;

        &::placeholder {
            color: var(--Brainstem-Grey);
        }
    }

    //.inputBox__close
    &__close {
        cursor: pointer;
        height: var(--close-icon-size);
        width: var(--close-icon-size);
    }

    //.inputBox__description
    &__description {
        color: var(--Wild-Dove);
        font-size: 13px;
        line-height: 140%;
    }
}
