@use "sass:math";
@import "@styles/variables";

.feedPages {
    //.feedPages__container
    &__container {
    }

    //.feedPages__top
    &__top {
        @media (min-width: ($mobile)) {
            width: 100%;
            margin: 0 auto;
            max-width: 378px;
        }

        &:not(:last-child) {
            margin-bottom: 40px;

            @media (max-width: ($mobile)) {
                margin-bottom: 20px;
            }
        }

    }

    //feedPages__postsWrapper
    &__postsWrapper {
        @media (min-width: ($tablet)) {
            display: grid;
            align-items: start;
            grid-template-columns: 1fr 300px;
            column-gap: 20px;
        }
        @media (min-width: ($containerMedia)) {
            grid-template-columns: 1fr 440px;
        }
    }

    //.feedPages__tabs
    &__tabs {
        &:not(:last-child) {
            margin-bottom: 32px;

            @media (max-width: ($mobile)) {
                margin-bottom: 24px;
            }
        }
    }

    //.feedPages__search
    &__search {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    //.feedPages__toysSorting
    &__toysSorting {
        display: flex;
        justify-content: space-between;
        margin: 0 42px 40px;

        &__sortItem {
            min-width: 215px;
        }

        @media (max-width: ($mobile)) {
            margin: 0 0 12px;
        }
    }
}
