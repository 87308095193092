@use "sass:math";
@import "@styles/variables";

.feedBanner {
    min-height: 542px;
    position: sticky;
    left: 0;

    @media (max-width: ($tablet)) {
        display: none;
    }
}