@use 'sass:math';
@import '@styles/variables';

.banner {
    border-radius: 16px;
    height: 100%;
    padding: 40px 24px;
    overflow: hidden;
    position: relative;

    @media (max-width: ($mobile)) {
        border-radius: 12px;
        padding: 24px 16px;
    }

    //.banner_vertical
    &_vertical {
        .banner__box {
            @media (min-width: ($tablet)) {
                grid-template-columns: auto;
                grid-template-rows: 1fr auto;

                .banner__content {
                    align-self: end;
                }

                .banner__images {
                }
            }
        }
    }

    //.banner__box
    &__box {
        height: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

        @media (max-width: ($mobile)) {
            grid-template-columns: 120px 1fr;
            column-gap: 20px;
        }
    }

    // .banner__images
    &__images {
        align-self: end;
    }

    // .banner__image
    &__image {
        position: relative !important;
        object-fit: contain;
        aspect-ratio: 1;

        max-width: 280px;
        margin: 0 auto;
        width: 100%;
    }

    // .banner__bg
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // .banner__content
    &__content {
        position: relative;

        display: flex;
        flex-direction: column;
        row-gap: 16px;

        @media (min-width: ($mobile)) {
            align-self: center;
            row-gap: 69px;
        }
    }

    // .banner__main
    &__main {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        @media (min-width: ($mobile)) {
            align-items: center;
            text-align: center;
        }
    }

    // .banner__title
    &__title {
        color: var(--white);
        font-family: $fontPlatformLC;
        font-size: 44px;
        font-weight: 500;
        line-height: 120%;
        text-transform: uppercase;

        @media (max-width: ($tablet)) {
            font-size: 24px;
        }
    }

    // .banner__subtitle
    &__subtitle {
        color: var(--white);
        font-family: $fontPlatformLC;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        @media (max-width: ($tablet)) {
            font-size: 16px;
            line-height: 140%;
        }
    }

    // .banner__text
    &__text {
        color: var(--white);
        font-size: 12px;
        font-weight: 500;
    }

    // .banner__button
    &__button {
        align-self: flex-start;
        @media (min-width: ($mobile)) {
            align-self: center;
            max-width: 240px;
            width: 100%;
        }
    }
}
