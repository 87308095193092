.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;

    .pageItem {
        font-size: 14px;
        line-height: 130%;
        font-weight: 500;
        color: var(--Thamar-Black);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--Thamar-Black);
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover:not(:disabled) {
            background-color: #f7fafc;
        }

        &:hover:not(:active) {
            background-color: var(--Thamar-Black);
            color: #fff;
        }

        &:disabled {
            cursor: default;
            opacity: 0.9;
        }

        &.active {
            background-color: var(--Thamar-Black);
            color: #fff;
        }
    }

    .arrow {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--Thamar-Black);
        border-radius: 6px;
        cursor: pointer;
        color: var(--Thamar-Black);
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover:not(:disabled) {
            background-color: #f7fafc;
            background-color: var(--Thamar-Black);
            color: #fff;
        }


        &:disabled {
            cursor: not-allowed;
            opacity: 0.9;
        }
    }
}

.ellipsis {
    font-size: 14px;
    line-height: 130%;
    font-weight: 500;
    color: var(--Thamar-Black);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Thamar-Black);
    border-radius: 6px;
    cursor: default;
}

.rotate {
    transform: rotate(180deg);
}
