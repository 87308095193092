.emptyList {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        width: 232px;
        height: 232px;
    }

    &__body {
        max-width: 230px;
    }


    &__title {
        font-size: 28px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.2px;
        text-align: center;
    }

    &__text {
        line-height: 140%;
        text-align: center;
    }
}