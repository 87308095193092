//Адаптивное свойство
@mixin adaptiveValue($property, $startSize, $minSize, $type: 1) {
    $addSize: $startSize - $minSize;

    @if $type==1 {
        //Только если меньше контейнера
        #{$property}: $startSize + px;

        @media (max-width: #{$containerWidth + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$containerWidth - $minWidth}));
        }

        @media (max-width: #{$minWidth + px}) {
            #{$property}: $minSize + px !important;
        }
    }

    @else if $type==2 {
        //Только если больше контейнера
        #{$property}: $startSize + px;

        @media (min-width: #{$containerWidth + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$maxWidth - $minWidth}));
        }
    }

    @else {
        //Всегда
        #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - #{$minWidth + px}) / #{$maxWidth - $minWidth}));
    }
}

@mixin countdownSmall() {
    --fcc-digit-font-size: 18px !important;
    --fcc-separator-size: 3px !important;
    --fcc-digit-block-width: 27px !important;
    --fcc-digit-block-height: 23px !important;
}

@mixin countdownMedium() {
    --fcc-digit-font-size: 28px !important;
    --fcc-separator-size: 5px !important;
    --fcc-digit-block-width: 45px !important;
    --fcc-digit-block-height: 33px !important;
}

@mixin Grid() {
    display: grid;
    grid-template-columns: 365px;
    grid-auto-rows: 1fr;
    justify-content: center;
    row-gap: 8px;

    @media (min-width: ($mobile)) {
        grid-template-columns: repeat(auto-fill, 270px);
        gap: 32px;
    }
}

@mixin GridVertical() {
    @include Grid;
    //grid-auto-rows: auto;

    @media (max-width: ($mobile)) {
        grid-template-columns: repeat(2, 179px);
        gap: 16px 7px;
        padding-bottom: 16px !important;
    }
}