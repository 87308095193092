@use "sass:math";
@import "@styles/variables";

.logoLoader {
    margin: 0 auto;
    width: 64px;
    aspect-ratio: 1;
    @media (max-width: ($mobile)) {
        width: 36px;
    }
}
